import React, { useState } from "react";
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/layout";
// import Hero from "../components/rsvpHero";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Img from "gatsby-image";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FaqPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    ADUtype: "",
    bestTime: "",
  });
  const { name, phone, email, address, ADUtype, bestTime } = formData;

  const [disabled, setDisabled] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  console.log(formData, "formDta herer");

  const onSubmit = (e) => {
    const url = "http://localhost:5000/hbh-firebase/us-central1/api";
    // const url = "https://us-central1-hbh-firebase.cloudfunctions.net/api";

    if (disabled) {
      toast.info("Form already submitted!");
    } else if (Object.values(formData).includes("")) {
      toast.info("Please fill out all fields");
    } else {
      setDisabled(true);

      axios.post(`${url}/contact/contactADU2`, formData).then(() => {
        toast.success("Info submitted!");
        setFormData({ name: "", phone: "", email: "", address: "", ADUtype: "", bestTime: "" });
      });
    }
  };

  const query = useStaticQuery(graphql`
    query FinancingQuery {
      contentfulAsset(title: { in: "RJ outer ADU" }) {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet title='My ADU Story - Financing' />
      <div className='financing-heading-image-container'>
        <Img alt='' className='financing-heading-photo' fluid={query.contentfulAsset.fluid} />
        <div className='financing-image-tint' />
        <h1 className='heading-title'>What are my financing options?</h1>
      </div>

      <div className='financing-page-container'>
        <p className='heading-description'>
          Whether you're building an ADU for your family, rental income, or creating extra space, lining up your finances is essential. Chances
          are you'd fall into one of three categories to finance your ADU.
          <ul style={{ marginTop: "2rem" }}>
            <li>
              <b>HELOC: </b>If you have equity in your house, you'll definitely want to consider getting a Home Equity Line of Credit (HELOC).
              This kind of loan allows you to borrow against the equity you have in your house.
            </li>
            <li>
              <b>Renovation Loan: </b>Homestyle Renovation Loan could cover construction costs by using the future value of the property
              including the completed ADU.
            </li>
            <li>
              <b>Adding a 2nd: </b>A 2nd Position Construction Loan which could cover your building costs. You could then refinance down into a
              single loan later.
            </li>
          </ul>
        </p>
        <h2 className='heading-subtitle'>Fill out your info below for a free consultation!</h2>
        <div className='form-container'>
          <div className='financing-grid-2'>
            <TextField disabled={disabled} name='name' value={name} onChange={onChange} label='Name' variant='standard' />
            <TextField disabled={disabled} name='phone' value={phone} onChange={onChange} label='Phone' variant='standard' />
          </div>

          <TextField disabled={disabled} name='email' value={email} onChange={onChange} label='Email' variant='standard' />
          <TextField disabled={disabled} name='address' value={address} onChange={onChange} label='Address' variant='standard' />
          <div className='financing-grid-2' style={{ marginBottom: "2rem" }}>
            <FormControl fullWidth disabled={disabled}>
              {/* <label>ADU Type</label> */}
              <InputLabel style={{ left: "-13px" }}>ADU Type</InputLabel>
              <Select value={ADUtype} label='ADU Type' name='ADUtype' variant='standard' onChange={onChange}>
                <MenuItem value='Attached ADU'>Attached ADU</MenuItem>
                <MenuItem value='Detached ADU'>Detached ADU</MenuItem>
                <MenuItem value='Garage Conversion'>Garage Conversion</MenuItem>
                <MenuItem value='Junior ADU'>Junior ADU</MenuItem>
                <MenuItem value='Prefab ADU'>Prefab ADU</MenuItem>
                <MenuItem value='2nd Story ADU'>2nd Story ADU</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              disabled={disabled}
              name='bestTime'
              value={bestTime}
              onChange={onChange}
              label='Best Time To Reach You'
              variant='standard'
            />
          </div>
          <button
            onClick={() => {
              onSubmit();
            }}
            className='cta cta-main'
            style={{ padding: "1rem 4rem", fontSize: "20px", margin: "1rem auto" }}
          >
            {disabled ? "Submitted!" : "Submit"}
          </button>
        </div>

        {/* <Button variant='contained'>Contained</Button> */}
        <ToastContainer delay={2000} />
      </div>
    </Layout>
  );
};

export default FaqPage;
